<template>
    <Settings/>
</template>


<script>
export default {
    data() {
        return {
            breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"System Settings", path:"", isActive: true}]
        }
    },
    components: {
        Settings: () => import("../../src/components/settings/Settings"),
    },
    created() {
        this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
    },
};
</script>

